import React, {useEffect} from "react";
import styles from "./style.module.scss";
import Header from "components/molecules/Header/Header";
import { Container } from "components";
import { useChameleonIdentify } from "utils/hooks/useChameleonIdentify";

const TermsOfService: React.FC = () => {
  useEffect(() => {
    window.analytics.page("Terms Of Service")
  }, [])
  useChameleonIdentify();
  
  return (
    <div className={styles.container} id="bg-container">
      <Header showMenu={false} />
      <Container>
        <div className={styles.container_box}>
          <h1>Terms of Service</h1>
  <p>INVICTOUS LLC DOING BUSINESS AS REVENUE INSTITUTE (“REVENUE
  INSTITUTE”) AND LICENSEE AGREE THAT THESE REVENUE INSTITUTE LICENSE
  TERMS AND CONDITIONS (<b>“THE TERMS”</b>) SHALL GOVERN THE RELATIONSHIP
  BETWEEN THE PARTIES AS TO ANY REVENUE INSTITUTE PRODUCTS OR
  SERVICES PROVIDED OR TO BE PROVIDED TO LICENSEE AS SET FORTH IN A
  DOCUMENT INCORPORATING THESE TERMS (AN <b>“ORDERING DOCUMENT”</b>). AS
  TO ANY PARTICULAR ORDERING DOCUMENT, THE ORDERING DOCUMENT, THE
  SERVICES DEFINITIONS AND SERVICE-SPECIFIC TERMS AND CONDITIONS,
  AND THESE TERMS TOGETHER CONSTITUTE THE AGREEMENT OF THE
  PARTIES AND ARE REFERRED TO COLLECTIVELY HEREIN AS THE
  <b>“AGREEMENT”</b>. IN THE EVENT OF ANY CONFLICT BETWEEN THE ORDERING
  DOCUMENT AND THESE TERMS, THESE TERMS SHALL PREVAIL UNLESS THE
  ORDERING DOCUMENT EXPRESSLY PROVIDES THAT IT IS MODIFYING THESE
  TERMS WITH RESPECT TO SUCH AGREEMENT.</p>
  <p>Licensee and Revenue Institute agree as follows:</p>
  <p><b>“Revenue Institute”</b> has the meaning set forth in Section 13.11 below.</p>
  <p><b>“Licensee”</b> means the party to whom Revenue Institute is to provide products or
  services pursuant to the Ordering Document (whether identified as “licensee”,
  “customer”, “client” or similar designation in the Ordering Document). If “Licensee”
  includes more than one legal person, the obligations imposed upon each shall be joint
  and several. The act of, notice from or to, or signature of any one or more of the
  persons included within “Licensee” shall be binding on all such persons with respect to
  all rights and obligations under this Agreement, including but not limited to any renewal,
  extension, termination, or modification of this Agreement.</p>
  <h4>1. SUBSCRIBED SERVICES, GRANT OF LICENSE</h4>
  <p>1.1 Revenue Institute, directly or through an affiliate, agrees to provide to Licensee the
  products and/or services set forth in the Ordering Document (the <b>“Services”</b>). The
  Services may include information (the <b>“Licensed Materials”</b>), access to and/or use of
  software or other technology (the <b>“Revenue Institute Technology”</b>), or other services
  including premium support. Specific Services may be defined by and are subject to the
  Services Definitions and Service-Specific Terms and Conditions included with the
  Ordering Document. Revenue Institute will make the Services available to the Licensee
  via password-protected online access accessible by Licensee with usernames and
  passwords, or as otherwise mutually agreed by the parties. Subject to the terms and
  conditions herein, Revenue Institute grants to Licensee a non-exclusive, non-
  transferrable license to access and use the Services in accordance with this Agreement
  and during the Term of this Agreement.</p>
  <p>1.2 The Services will be provided as they exist and are updated and amended
  throughout the Term. Information provided as part of any Licensed Materials may be
  updated on an ongoing basis and provided according to the criteria used to define the
  scope of the subscribed Services. Licensee understands and acknowledges that the
  contents of Licensed Materials will change over time as the data is updated, and that at
  any given time it has a right to access and use the data to which it is subscribed as it
  exists at that time. Certain portions of the Services may be provided by Revenue
  Institute’s third party licensors, and Revenue Institute’s ability to provide such
  information may be subject to the willingness of such licensors to continue to contract
  with Revenue Institute. Features and functions of the Revenue Institute Technology are
  provided “as is” and as they may be modified, supplemented, or removed from time to
  time in Revenue Institute’s sole discretion. Revenue Institute shall have no liability to
  Licensee for any modification to any Service, provided that the product or service
  provided substantially conforms to the description in the Ordering Document.</p>
  <p>1.3 Ownership. Licensee acknowledges and agrees that, as between Licensee and
  Revenue Institute, the Licensed Materials, the Revenue Institute Technology, and any
  related documentation (including, without limitation, the content, layout, functions,
  design, appearance, trademarks, service marks, copyrights, patents, and other
  intellectual property comprising the Licensed Materials or Revenue Institute
  Technology) are the property of Revenue Institute, whether or not they are
  trademarked, copyrighted, or patented. Licensee acknowledges and agrees that this
  Agreement does not transfer any ownership, right, title, or interest in the Licensed
  Materials or Revenue Institute Technology, nor any part thereof, except the limited
  license provided hereunder, and Licensee expressly disclaims and waives any and all
  claims to any ownership interest in any such information or materials. This includes,
  without limitation, any Licensed Materials that Licensee downloads, prints, saves, or
  incorporates into other materials. Licensee further acknowledges and agrees that the
  Licensed Materials, in whole or in part, are unique, special, and valuable. Subject to the
  limited rights expressly granted hereunder, Revenue Institute, its affiliates and/or its
  licensors reserve all right, title, and interest in and to the Licensed Materials and
  Revenue Institute Technology, including all related intellectual property rights. No rights
  are granted to Licensee hereunder other than as expressly set forth herein. All other
  trademarks, registered trademarks, product names, and company names or logos
  mentioned in or on the Revenue Institute Technology are the property of their respective
  owners. Reference to any products, services, processes, or other information, by trade
  name, trademark, manufacturer, supplier, or otherwise does not constitute or imply
  endorsement, sponsorship, or recommendation thereof by Revenue Institute nor any
  endorsement, sponsorship, or recommendation of Revenue Institute by any such
  person or entity.</p>
  <p>1.4 <b>Third Party Applications</b>. “Third Party Applications” means computer software
  programs and other technology that are provided or made available to Licensee or
  Authorized Users by third parties, including those with which the Revenue Institute
  Technology may interoperate, including, for example, Licensee’s CRM, marketing
  automation software, or sales enablement software, if any. Revenue Institute may make
  available certain <b>“Integration Tools”</b>, which consist of Revenue Institute Technology
  designed to allow Licensee to use Revenue Institute Technology and the Licensed
  Materials in such a way as to interoperate with one or more Third Party Applications.
  Revenue Institute is not responsible for and does not endorse any Third Party
  Applications or websites linked to by Revenue Institute Technology. Licensee shall not
  integrate Licensed Materials into any Third Party Applications for the purpose of
  allowing persons who are not Authorized Users (defined below) to access or use the
  Licensed Materials.</p>
  <p>1.5 Quality Guarantee, Availability. A <b>“Licensed Materials Contact”</b> means a record
  regarding a natural person, consisting of at a minimum such person’s name and
  company affiliation, viewable in Revenue Institute’s database and made available to
  Licensee as part of any of the Services. While Revenue Institute strives to provide the
  highest level of accuracy possible per record, no accuracy or uptime is guaranteed.</p>
  <p>1.6 Support. Revenue Institute will provide reasonable assistance and ongoing support
  to assist Licensee and Authorized Users in accessing the Licensed Materials. Revenue
  Institute will make its personnel available by email, online chat, or phone for feedback,
  problem solving, or general questions between the hours of 9:00 a.m. and 5:00 p.m.
  Eastern Time (Monday – Friday).</p>
  <h4>2. AUTHORIZED USE OF LICENSED MATERIALS AND REVENUE INSTITUTE
  TECHNOLOGY, RESTRICTIONS</h4>
  <p>2.1 Authorized Users. Licensee shall be entitled to designate persons as users of the
  Services (<b>“Authorized Users”</b>) up to the number of Authorized Users subscribed as
  stated in the Ordering Document. Each Authorized User will be provided a unique
  username and password. Such usernames and passwords may not be shared and may
  not under any circumstances be used by anyone who is not an Authorized User. If any
  Authorized User’s login credentials are disclosed to any person who is not an
  Authorized User but who would satisfy the qualification requirements of Section 2.2
  hereof, such disclosure shall constitute Licensee’s subscription as of the time of such
  disclosure to the number of additional Authorized Users equal to the number of persons
  to whom such credentials were disclosed. If Licensee designates additional persons as
  Authorized Users beyond the number subscribed, such designation may be deemed by
  Revenue Institute as Licensee’s subscription to such additional number of Authorized
  Users. In the event of such subscription, Revenue Institute may charge Licensee a
  corresponding additional Subscription Fee equal to the prevailing per-Authorized User
  rate multiplied by the period from the date of designation until the end of the then-
  current Term. Licensee shall be responsible for compliance with the terms of this
  Agreement by all Authorized Users, including, without limitation, the restrictions on use
  and transfer of Licensed Materials set forth herein. Licensee acknowledges and agrees
  that Authorized Users must provide Revenue Institute with certain identifying
  information, including their name and a business email address, and that Authorized
  Users may be required to accept an end-user license agreement agreeing to Revenue
  Institute’s privacy policy and representing that they are authorized to access the
  Services on Licensee’s behalf.</p>
  <p>2.2 Qualification of Authorized Users. Licensee shall not designate any person as an
  Authorized User unless such person is: (a) a natural person and (b) an employee of
  Licensee. Licensee may designate a non-employee (i.e., an independent contractor) as
  an Authorized User only with Revenue Institute’s prior permission and provided
  Licensee takes reasonable steps to ensure such non-employee uses the Services only
  as permitted under this Agreement. If the employment of any Authorized User that was
  in effect as of the date such person was designated as an Authorized User terminates,
  such person’s authorization to access the Services shall be revoked automatically
  without any further action by Revenue Institute. In the event of a termination as
  described in the previous sentence, Licensee shall promptly notify Revenue Institute
  and take all reasonable steps to ensure that such person ceases accessing the
  Services. Licensee may reassign Authorized User designations in good faith, subject to
  the foregoing qualification requirements.</p>
  <p>2.3 Authorized Uses, Restrictions. Licensee shall not access or use the Services for any
  purpose except the business-to-business sales, marketing, recruiting, or business
  development activities of Licensee. Licensee shall not access or use the Licensed
  Materials for the benefit of or on behalf of any person or entity except Licensee. Subject
  to Licensee’s compliance with all applicable laws, rules, and regulations, Licensee may
  use the Services to: (a) view the Licensed Materials; (b) communicate with any
  Licensed Materials Contact in a manner that relates to such person’s profession,
  business, or employment; and (c) identify prospective sales opportunities, research
  Licensee’s existing customers and prospects, and otherwise analyze the Licensed
  Materials in a manner relating to Licensee’s business-to-business sales, marketing,
  recruiting, and business development activities. Licensee shall not permit anyone who is
  not an Authorized User to access or use the Services, including any Licensed Materials
  or any Authorized User login credentials. Licensee shall not distribute, sublicense,
  transfer, sell, offer for sale, disclose, or make available any of the Licensed Materials or
  any part of the Services to any third party. Except through services provided by
  Revenue Institute or its affiliates or as expressly permitted by Revenue Institute,
  Licensee shall not: (x) use the Licensed Materials to create audience segmentation for
  or otherwise place digital ads or ads in digital media (also known as onboarding or
  digital activation); or (y) utilize or incorporate any Revenue Institute API credentials into
  any Third Party Applications or use API access to create or develop audience
  segmentation outside of services provided by Revenue Institute. Licensee shall not
  incorporate any portion of the Services or Licensed Materials into Licensee’s own
  products or services. Upon expiration or termination of this Agreement for any reason,
  Licensee shall cease accessing the Services and shall cease using the Licensed
  Materials in any way. Notwithstanding the foregoing, where Licensee has, through using
  the Licensed Materials in a manner permissible under this Agreement, received
  responsive communication from a Licensed Materials Contact, Licensee shall not be
  required to delete such Licensed Materials Contact record upon expiration or
  termination hereof, and may continue to use such information in a manner otherwise
  consistent with this Agreement. Licensee is solely responsible for any communications
  between Licensee or any Authorized User and any Licensed Materials Contact.
  Licensee shall not use the Services to determine a consumer’s eligibility for (a) credit or
  insurance for personal, family or household purposes, (b) employment or (c) a
  government license or benefit or (d) any other purpose governed by the Fair Credit
  Reporting Act.</p>
  <p>2.4 Permitted Use of Revenue Institute Technology, Restrictions. Licensee is permitted
  to use the Revenue Institute Technology solely for the purpose of accessing and using
  the Licensed Materials as permitted by this Agreement. Licensee will not (a) reverse
  assemble, reverse engineer, decompile, or otherwise attempt to derive source code
  from any of the Revenue Institute Technology; (b) reproduce, modify, create, or prepare
  derivative works of any of the Revenue Institute Technology or related documentation;
  (c) distribute or display any of the Revenue Institute Technology or related
  documentation other than to Authorized Users; (d) share, sell, rent, or lease or
  otherwise distribute access to the Revenue Institute Technology, or use the Revenue
  Institute Technology to operate any timesharing, service bureau, or similar business; (e)
  create any security interest in the Revenue Institute Technology; (f) alter, destroy, or
  otherwise remove any proprietary notices or labels on or embedded within or on the
  Revenue Institute Technology or related documentation; (g) disclose the results of any
  Revenue Institute Technology or program benchmark tests to any third parties without
  Revenue Institute’s prior written consent; (h) employ any measure intended to
  circumvent limitations to purchased credits or Authorized Users; or (i) use automated
  means, such as bots or crawlers, to access any Revenue Institute Technology or extract
  information therefrom (except such means as are included within the Revenue Institute
  Technology, such as Integration Tools, or such other means as are expressly approved
  in advance in writing by Revenue Institute). Licensee may use Revenue Institute
  Technology only in accordance with this Agreement and not for the benefit of any third
  party, except with Revenue Institute’s express prior written permission.</p>
  <p>2.5 Limitations on Use of the Services. Licensee shall use the Services in a responsible
  and professional manner consistent with the intended and permissible uses herein and
  consistent with standard industry practice. Licensee shall not override or circumvent, or
  attempt to override or circumvent, any security feature, control, or use limits of the
  Revenue Institute Technology. Licensee will not use the Licensed Materials or Revenue
  Institute Technology for commercial purposes not permitted under this Agreement and
  shall not designate any person as an Authorized User if Licensee has reason to believe
  such person is likely to use the Services on behalf of a third party or otherwise in
  violation of this Agreement. Revenue Institute may use technological means to place
  reasonable use limits to prohibit excessive use, including excessive downloads or
  screen views that indicate a violation of this Agreement, such as sharing with third
  parties or attempting to circumvent limitations to purchased credits (if applicable). If
  Licensee’s access to the Services is limited under this paragraph, it may request that
  the limit be removed, and Revenue Institute may remove or modify a particular limitation
  if it determines in its sole and absolute discretion that the proposed use by Licensee is
  in good faith and otherwise consistent with this Agreement.</p>
  <p>2.6 Identification of Licensed Materials. Any Licensed Materials that are downloaded
  and/or integrated into any Third Party Applications must be maintained with identifying
  information indicating that such materials originated with Revenue Institute by, for
  example, maintaining a leadsource of “Revenue Institute.”</p>
  <p>2.7 Licensee Data. Licensee is solely responsible for all data, graphics, images, files,
  information, text, voice content, recordings, and other content and materials that are
  collected, uploaded, posted, delivered, provided, or otherwise transmitted or stored by
  Licensee in connection with Licensee’s use of the Services (collectively, <b>“Licensee
  Data”</b>), and Licensee represents and warrants that it has all rights and authority
  necessary to  provide Licensee Data to Revenue Institute without violation of any third
  party rights, including without limitation, any privacy rights, publicity rights, copyrights,
  trademarks, contract rights, or any other intellectual property or proprietary rights.
  Licensee shall be solely responsible for making any required notices (including without
  limitation any privacy notices required by applicable local, state, federal, and
  international laws and regulations) and for obtaining any required consents sufficient to
  authorize Revenue Institute’s performance of its obligations and exercise of its rights as
  set forth in this Agreement.</p>
  <p>2.8 Unauthorized Access and Use. In the event Revenue Institute has a reasonable
  belief that Licensee or any Authorized User is engaged in or facilitated any unauthorized
  access or use of the Licensed Materials or Revenue Institute Technology in violation of
  this Agreement, Revenue Institute, in its sole discretion, may immediately suspend
  Licensee’s access to the Licensed Materials and/or Revenue Institute Technology until
  such violation is resolved to Revenue Institute’s reasonable satisfaction. Revenue
  Institute will have no liability to Licensee for such period of suspension and a
  suspension shall have no effect on the Term of this Agreement nor on Licensee’s
  obligation to pay the Subscription Fee.</p>
  <h4>3. TERM AND TERMINATION</h4>
  <p>3.1 Term. The Initial Term of the Agreement is that which is set forth in the Ordering
  Document (together with any period of extension under Section 3.2 hereof, the “Term”).
  The Agreement is not cancellable and shall remain in effect until it expires or is earlier
  terminated according to its terms.</p>
  <p>3.2 Termination. Either party may terminate this Agreement immediately, without further
  obligation to the other party, in the event of a material breach of this Agreement by the
  other party that is not remedied within twenty-one (21) days after the breaching party’s
  receipt of written notice of such breach. The parties may terminate this Agreement at
  any time upon their mutual Agreement.</p>
  <p>3.3 Effect of Termination.</p>
  <p>3.3.1 Expiration or Termination for any Reason. Upon expiration or termination of this
  Agreement for any reason, Licensee acknowledges and agrees that its access to the
  Services may be automatically terminated, all passwords and individual accounts
  removed, and all information that has been uploaded into Revenue Institute’s systems
  by Licensee destroyed. Upon expiration or termination of this Agreement for any
  reason, unless otherwise provided herein, Licensee agrees to destroy any and all
  copies of Licensed Materials and any information it has obtained from the Licensed
  Materials, whether in hard copy or electronic form.</p>
  <p>3.3.2 Termination by Revenue Institute. If this Agreement is terminated by Revenue
  Institute due to an uncured material breach by Licensee, all Subscription Fees payable
  to be paid to Revenue Institute for the remainder of the then-current Term shall be
  immediately due and payable to Revenue Institute, and Licensee shall promptly remit all
  such fees to Revenue Institute.</p>
  <p>3.3.3. Termination by Licensee. If this Agreement is terminated by Licensee due to an
  uncured material breach by Revenue Institute, Revenue Institute shall promptly refund
  the pro-rata amount of any pre-paid Subscription Fees attributable to periods after the
  date of such termination.</p>
  <h4>4. FEES AND TAXES</h4>
  <p>4.1 Licensee shall pay all fees stated in the Ordering Document and any other fees
  applicable to its subscription to Services as provided hereunder (the <b>“Subscription
  Fee”</b>). All Subscription Fees are due upon execution of the Ordering Document, or
  notice of a deemed subscription as provided herein, and payable on the terms set forth
  therein. If no payment schedule is specified for any Subscription Fees, the entire
  amount shall be payable within 30 days of Revenue Institute’s transmission to Licensee
  of an appropriate invoice. All amounts payable by Licensee under this Agreement will
  be paid to Revenue Institute without setoff or counterclaim, and without any deduction
  or withholding. Revenue Institute’s acceptance of partial payment or any payment of
  less than the full amount payable at any given time shall not constitute a waiver or
  release of Revenue Institute’s right to unpaid amounts.</p>
  <p>4.2 If Licensee fails to timely make any payment of Subscription Fees, Revenue
  Institute may, in its sole discretion, take any or all of the following actions: (a) restrict or
  suspend Licensee’s access to the Licensed Materials until all past-due payments are
  made, (b) terminate this Agreement, or (c) accelerate the payment of Subscription Fees
  such that all unpaid Subscription Fees shall be immediately payable. Revenue Institute
  shall have the right to charge interest at the rate of 1.5% per month (or, if less, the
  highest rate permitted by law) on any late payments. Restriction or suspension of
  Licensee’s online access to the Licensed Materials during period of non-payment shall
  have no effect on the Term of this Agreement nor on Licensee’s obligation to pay the
  Subscription Fee.</p>
  <p>4.3 Licensee is responsible for any applicable taxes, including, without limitation, any
  sales, use, levies, duties, or any value added or similar taxes payable with respect to
  Licensee’s subscription and assessable by any local, state, provincial, federal, or
  foreign jurisdiction. Unless expressly specified otherwise in the Ordering Document, all
  fees, rates, and estimates exclude sales taxes. If Revenue Institute believes any such
  tax applies to Licensee’s subscription and Revenue Institute has a duty to collect and
  remit such tax, the same may be set forth on an invoice to Licensee unless Licensee
  provides Revenue Institute with a valid tax exemption certificate, direct pay permit, or
  multi-state use certificate, and shall be paid by Licensee immediately or as provided in
  such invoice. Licensee shall indemnify, defend, and hold harmless Revenue Institute
  and its officers, directors, employees, shareholders, agents, partners, successors, and
  permitted assigns against any and all actual or threatened claims, actions, or
  proceedings of any taxing authority arising from or related to the failure to pay taxes
  owed by Licensee, except to the extent that any such claims, action, or proceeding is
  directly caused by a failure of Revenue Institute to remit amounts collected for such
  purpose from Licensee. Revenue Institute is solely responsible for taxes based upon
  Revenue Institute’s net income, assets, payroll, property, and employees.</p>
  <h4>5. DATA PROTECTION AND CONFIDENTIALITY</h4>
  <p>5.1 Licensee acknowledges and agrees that Revenue Institute will operate in
  accordance with its published Privacy Policy (available at <a className="link" href="https://revenueinstitute.com/privacy-policy" rel="noreferrer" target="_blank">RevenueInstitute.com/privacy-policy</a> or as Revenue Institute may otherwise indicate), which is
  incorporated herein by reference.</p>
  <p>5.2 <b>“Confidential Information”</b> of a party means such party’s (or its affiliate’s):
  inventions, discoveries, improvements, and copyrightable material not yet patented,
  published, or copyrighted; special processes and methods, whether for production
  purposes or otherwise, and special apparatus and equipment not generally available or
  known to the public; current engineering research, development, design projects,
  research and development data, technical specifications, plans, drawings and sketches;
  business information such as product costs, vendor and customer lists, lists of approved
  components and sources, price lists, production schedules, business plans, and sales
  and profit or loss information not yet announced or not disclosed in any other way to the
  public; and any other information or knowledge not generally available to the public.</p>
  <p>“Confidential Information” does not include the Licensed Materials (which are subject to
  other restrictions under this Agreement) nor otherwise include business contact or
  firmographic information regarding third parties. All business terms of this Agreement,
  including, but not limited to, pricing and access, shall be considered Confidential
  Information of Revenue Institute.</p>
  <p>5.3 Each party shall keep in confidence all Confidential Information of the other party
  obtained prior to or during the Term of this Agreement and shall protect the
  confidentiality of such information in a manner consistent with the manner in which such
  party treats its own confidential material, but in no event with less than reasonable care.
  Without the prior written consent of the other party, a party shall not disclose or make
  available any portion of the other party’s Confidential Information to any person, firm,
  association, or corporation, or use such Confidential Information, directly or indirectly,
  except for the performance of this Agreement. The foregoing restrictions shall not apply
  to Confidential Information that: (a) was known to such party (as evidenced by its written
  record) or was in the public domain prior to the time obtained by such party; (b) was
  lawfully disclosed to such party by a third party who did not receive it directly or
  indirectly from such party and who is under no obligation of secrecy with respect to the
  Confidential Information; (c) became generally available to the public, by publication or
  otherwise, through no fault of such party or (d) was developed independently by the
  receiving party as evidenced by written records without reference to the Confidential
  Information of the other party. The parties shall take all necessary and appropriate steps
  in order to ensure that its employees and subcontractors adhere to the provisions of this
  section. All Confidential Information shall be returned to the disclosing party or
  destroyed upon receipt by the receiving party of a written request from the disclosing
  party. The receiving party may disclose the disclosing party’s Confidential Information to
  the extent required by law or legal process, provided, however, the receiving party will
  (unless prohibited by law or legal process): (a) give the disclosing party prior written
  notice of such disclosure to afford the disclosing party a reasonable opportunity to
  appear, object, and obtain a protective order or other appropriate relief regarding such
  disclosure; (b) use diligent efforts to limit disclosure to that which is legally required; and
  (c) reasonably cooperate with the disclosing party, at the disclosing party’s expense, in
  its efforts to obtain a protective order or other legally available means of protection.</p>
  <p>5.4 Personal Information. To the extent that either party transmits or receives personal
  information under this Agreement, such party shall comply with all applicable laws,
  rules, and regulations regarding privacy and the lawful processing of personal
  information. Each party shall have an obligation to immediately notify the other party if it
  makes a determination that it can no longer meet compliance obligations under
  applicable privacy or data protection laws. To the extent that personal data obtained by
  Licensee under this Agreement is subject to the E.U. General Data Protection
  Regulation (the <b>“GDPR”</b>) or the retained version of the GDPR applicable in the UK (the
  <b>“UK GDPR”</b>, together with the GDPR the <b>“UK/EU GDPR”</b>), each party agrees: (a) that it
  is a “controller” with respect to such data as defined in the GDPR; (b) to comply with all
  applicable provisions; and (c) that the Controller-to-Controller Data Processing
  Addendum is incorporated by reference into these Terms in relation to such personal
  data. Notwithstanding anything in this Agreement to the contrary, Licensee shall not use
  any information subject to the UK/EU GDPR unless it is for a purpose that constitutes a
  “legitimate interest” (including direct marketing) as defined in the applicable UK/EU
  GDPR, or Licensee has another lawful basis to process such information. Within the
  Revenue Institute Technology, Revenue Institute may publish a list of persons who
  have requested that their personal information be removed from Revenue Institute’s
  database. Licensee agrees to review such list on a regular basis (no less frequently
  than once per month) and to remove from its possession any Licensed Materials
  Contact records relating to such persons in its possession, unless Licensee has
  established an independent lawful basis to process such person’s personal information.
  To the extent that any Services may involve Revenue Institute receiving personal data
  from Licensee that is subject to the UK/EU GDPR, the Controller-to-Processor Data
  Processing Addendum is incorporated by reference into these Terms and shall set out
  the status and responsibilities of the parties in relation to such personal data.</p>
  <p>5.5 Data Cleansing, Matching, and Related Requests. Licensee acknowledges that,
  through the use of Integration Tools or otherwise, Licensee may have the opportunity to
  transmit business contact information to Revenue Institute for purposes of matching,
  cleansing, or updating records with information from Revenue Institute’s database. In
  the event such information is transmitted to Revenue Institute, Revenue Institute will
  make commercially reasonable efforts consistent with its research protocols and
  priorities, to respond to match and clean and append requests by researching and/or
  verifying business contact information so submitted and supplementing Revenue
  Institute’s commercial database with information Revenue Institute is able to verify.
  Revenue Institute may also use email deliverability data (such as email “bounce” data)
  accessible through Licensee’s use of Revenue Institute Technology to improve
  Revenue Institute’s database by, for example, eliminating invalid email addresses from
  the Licensed Materials.</p>
  <p>5.6 Related Information. Revenue Institute may access, collect, and use any information
  from or relating to Licensee and Licensee’s use of the Services (<b>“Related Information”</b>)
  for customer and technical support, for regulatory and third party compliance purposes,
  to protect and enforce Revenue Institute’s rights, to monitor compliance with and
  investigate potential breaches of the terms of this Agreement, and to recommend
  additional products or services to Licensee. Revenue Institute may share this
  information with Revenue Institute’s partners or affiliates for the same purposes.
  Licensee grants Revenue Institute and Revenue Institute affiliates the perpetual right to
  use Related Information for purposes such as to test, develop, improve, and enhance
  Revenue Institute’s products and services, and to create and own derivative works
  based on Related Information, so long as neither Licensee, Authorized User nor any
  other individual is identifiable as the source of such information.</p>
  <h4>6. REPRESENTATIONS AND WARRANTIES</h4>
  <p>6.1 Each party represents and warrants that: (a) it is duly organized and validly existing
  and authorized to do business in the jurisdictions where it operates; and (b) it has the
  requisite power and authority to enter this Agreement and entering and complying with
  its obligations under this Agreement does not violate any legal obligation by which such
  party is bound.</p>
  <p>6.2 Licensee represents and warrants, and covenants that it will not, in connection with
  this Agreement, including its use of or access to the Services, engage in, encourage, or
  permit conduct that violates or would violate any applicable law, rule, or regulation or
  any right of any third party.</p>
  <p>6.3 Revenue Institute represents and warrants that it possesses all necessary authority
  and permissions to provision Licensee with access to the Licensed Materials and
  Revenue Institute Technology.</p>
  <h4>7. REMEDIES</h4>
  <p>7.1 Remedies not Exclusive. No remedy provided in this Agreement shall be deemed
  exclusive of any other remedy that a party may have at law or in equity unless it is
  expressly stated herein that such remedy is exclusive.</p>
  <p>7.2 Provisional Remedies. Each party recognizes that the unauthorized disclosure of
  Confidential Information or, as to Licensee, Licensed Materials, may cause irreparable
  harm to the other party for which monetary damages may be insufficient, and in the
  event of such disclosure, such other party shall be entitled to seek an injunction,
  temporary restraining order, or other provisional remedy as appropriate without being
  required to post bond or other security.</p>
  <p>7.3 Liquidated Damages. Licensee acknowledges that the Licensed Materials are for its
  own use only, and that the disclosure to a third party of Licensed Materials Contacts, or
  the use of Licensed Materials Contacts for the benefit of or on behalf of any third party,
  will cause damage to Revenue Institute in an amount that is difficult to quantify. In order
  to avoid the time and expense of quantifying damages, if Licensee, negligently or
  intentionally, discloses Licensed Materials Contacts to a third party, permits a third party
  to access any Licensed Materials Contact records through use of login credentials to
  Revenue Institute Technology issued to any Authorized User, or uses Licensed
  Materials Contacts for the benefit of or on behalf of any third party, then Revenue
  Institute shall be entitled to damages from Licensee in the liquidated amount equal to
  $2.00 per Licensed Materials Contact record that is so disclosed, used, or made
  available per third party recipient or beneficiary, as applicable.</p>
  <h4>8. ATTORNEY FEES, DISPUTE RESOLUTION, CLASS ACTION WAIVER</h4>
  <p>8.1 Attorney Fees. In the event of any dispute arising under this Agreement, the
  prevailing party shall be entitled to recover its reasonable costs and expenses actually
  incurred in endeavoring to enforce the terms of this Agreement, including reasonable
  attorney fees.</p>
  <p>8.2 Mandatory Arbitration. Except for Litigation Claims (defined below), any dispute,
  claim, or controversy arising out of or relating to this Agreement, including, without
  limitation (a) claims relating to the breach, termination, enforcement, interpretation or
  validity thereof, (b) claims alleging tortious conduct (including negligence) in connection
  with the negotiation, execution, or performance thereof, or (c) the determination of the
  scope or applicability of this agreement to arbitrate, shall be settled by arbitration
  administered by JAMS pursuant to its Comprehensive Arbitration Rules and Procedures
  and in accordance with the Expedited Procedures in those Rules or pursuant to JAMS’
  Streamlined Arbitration Rules and Procedures. The arbitration shall be heard by a single
  arbitrator. The arbitration award shall be final and binding, and such award may be
  entered in any court having jurisdiction. This section shall not preclude parties from
  seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction.
  The arbitrator shall have the power to award any remedy provided under applicable law,
  except that the arbitrator shall have no power to award: (a) punitive, exemplary, or
  multiple damages under any legal theory, except in the event of a party’s or its agent’s
  gross negligence or intentional misconduct; (b) mandatory or prohibitory injunctive relief,
  except for temporary relief in aid of the arbitration or to secure the payment of an award;
  or (c) any damages in excess of the limits set forth in this section or Section 10
  (Limitation of Liability) of this Agreement.</p>
  <p>8.3 Class Action Waiver. No party shall commence or seek to prosecute or defend any
  dispute, controversy, or claim based on any legal theory arising out of or relating to this
  Agreement, or the breach thereof, other than on an individual, non-class, non-collective
  action basis. No party shall seek to prosecute or defend any dispute, controversy, or
  claim arising out of or relating to this Agreement, or the breach thereof, in a
  representative or private attorney general capacity. The arbitrator shall not have the
  power to consolidate any arbitration under this Agreement with any other arbitration,
  absent agreement of all parties involved, or otherwise to deal with any matter on a non-
  individual, class, collective, representative, or private attorney general basis.</p>
  <p>8.4 Litigation Claims. The following claims (<b>“Litigation Claims”</b>) shall be litigated and
  not arbitrated: (a) claims against a party to this Agreement under the provisions
  involving claims by third parties; (b) claims by a party for the unauthorized use, or the
  misuse, by the other party of the first party’s Confidential Information; (c) claims by
  Revenue Institute to collect Subscription Fees; and (d) claims for mandatory or
  prohibitory injunctive relief, except for temporary relief in aid of arbitration or to secure
  the payment of an arbitration award under this Agreement. The Litigation Claims are not
  subject to arbitration and are expressly excluded by the parties from arbitration unless
  otherwise agreed in writing.</p>
  <h4>9. INDEMNIFICATION</h4>
  <p>9.1 Licensee agrees to indemnify, defend, and hold harmless Revenue Institute and its
  officers, directors, employees, shareholders, agents, partners, successors, and
  permitted assigns from and against any and all actual or threatened claims of third
  parties arising out of or in connection with (a) Licensee’s access or use of the Licensed
  Materials in violation of any law, (b) Licensee’s violation of any provision of this
  Agreement, (c) Licensee’s sending of any information, messages, or materials to any
  Licensed Materials Contact (including, but not limited to, through e-mail, mail, or fax) in
  violation of any law or the rights of any third party, or (d) the use of any Licensed
  Materials or Revenue Institute Technology by any third party to whom Licensee has
  granted access (including access obtained by such third party through use of the
  usernames and passwords assigned to Licensee and its personnel).</p>
  <p>9.2 Revenue Institute shall indemnify Licensee for any damages finally awarded by any
  court of competent jurisdiction against Licensee in, or for amounts paid by Licensee
  under a settlement approved by Revenue Institute in writing of, any legal proceeding
  brought by a third party alleging that the Licensed Materials or Revenue Institute
  Technology infringes upon or violates the intellectual property rights of any such third
  party. The foregoing indemnification obligations do not apply if (a) the allegation does
  not state with specificity that the Services are the basis of the claim against Licensee;
  (b) a claim against Licensee arises from the use or combination of the Services or any
  part thereof with software, hardware, data, or processes not provided by Revenue
  Institute, if the Services or use thereof would not infringe without such combination; or
  (c) a claim against Licensee arises from Licensee’s breach of this Agreement.</p>
  <p>9.3 As a condition to any right to indemnification under this Agreement, the indemnified
  party must (a) promptly give the indemnifying party written notice of the claim or
  proceeding, (b) give the indemnifying party sole control of the defense and settlement of
  the claim or proceeding (except that the indemnifying party may not settle any claim or
  proceeding unless it unconditionally releases the indemnified party of all liability), and
  (c) give the indemnifying party all reasonable assistance, at the indemnifying party’s
  expense. This section states the indemnifying party’s sole liability to, and the
  indemnified party’s exclusive remedy against, the other party for any claim or
  proceeding subject to indemnification hereunder.</p>
  <h4>10. LIMITATION OF LIABILITY</h4>
  <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT FOR
  INSTANCES OF A PARTY’S OR ITS AGENT’S GROSS NEGLIGENCE OR
  INTENTIONAL MISCONDUCT, IN NO EVENT WILL EITHER PARTY BE LIABLE TO
  THE OTHER PARTY FOR ANY PUNITIVE, EXEMPLARY, MULTIPLE, INDIRECT,
  CONSEQUENTIAL, SPECIAL, LOST BUSINESS, LOST OR CORRUPTED DATA, OR
  LOST PROFITS DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT,
  WHETHER FORESEEABLE OR UNFORESEEABLE, EVEN IF ADVISED OF THE
  POSSIBILITY OF SUCH DAMAGES. LICENSEE’S SOLE AND EXCLUSIVE REMEDY
  FOR ANY UNCURED BREACH BY REVENUE INSTITUTE OF ITS OBLIGATIONS
  UNDER THIS AGREEMENT IS TERMINATION BY WRITTEN NOTICE TO REVENUE
  INSTITUTE, AND REFUND OF A PRORATED PORTION OF THE SUBSCRIPTION
  FEES THAT LICENSEE HAS PAID. REVENUE INSTITUTE’S MAXIMUM LIABILITY
  TO LICENSEE SHALL BE THE AMOUNTS ACTUALLY PAID TO REVENUE
  INSTITUTE BY LICENSEE UNDER THIS AGREEMENT IN THE TWELVE (12)
  MONTHS PRECEDING THE EVENT GIVING RISE TO LICENSEE’S CAUSE OF
  ACTION. EXCEPTING LIABILITY ARISING FROM LICENSEE’S OR ITS AGENT’S
  GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT OR LICENSEE’S
  INDEMNIFICATION OBLIGATIONS HEREUNDER, LICENSEE’S MAXIMUM LIABILITY
  TO REVENUE INSTITUTE HEREUNDER SHALL BE TWO TIMES (2X) THE AMOUNT
  OF THE SUBSCRIPTION FEE.</p>
  <h4>11. DISCLAIMER OF WARRANTIES</h4>
  <p>EXCEPT FOR ANY EXPRESS REPRESENTATIONS AND WARRANTIES STATED
  HEREIN, THE LICENSED MATERIALS, REVENUE INSTITUTE TECHNOLOGY, AND
  ANY OTHER SERVICES ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE”
  BASIS, AND NEITHER PARTY MAKES ANY ADDITIONAL REPRESENTATION OR
  WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR
  BY OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER
  AND EACH PARTY EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF
  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY,
  ACCURACY, TITLE, AND NON-INFRINGEMENT. NEITHER PARTY WILL HAVE THE
  RIGHT TO MAKE OR PASS ON ANY REPRESENTATION OR WARRANTY ON
  BEHALF OF THE OTHER PARTY TO ANY THIRD PARTY.</p>
  <p>REVENUE INSTITUTE DOES NOT REPRESENT, COVENANT, WARRANT, OR
  PROMISE THAT ANY OF THE SERVICES MAY BE USED OR RELIED UPON BY
  LICENSEE OR ANY OTHER PARTY TO COMPLY WITH ANY LAW, RULE,
  REGULATION, INDUSTRY STANDARD, OR POLICY, NOR THAT ANY OF THE
  SERVICES WILL RENDER LICENSEE NOR ANY OTHER PARTY COMPLIANT WITH
  ANY LAW, RULE, REGULATION, INDUSTRY STANDARD, OR POLICY, AND
  REVENUE INSTITUTE EXPRESSLY DISCLAIMS TO THE FULLEST EXTENT
  PERMISSIBLE BY APPLICABLE LAW ANY SUCH REPRESENTATION, COVENANT,
  WARRANTY, OR PROMISE. IF AND TO THE EXTENT THAT LICENSEE USES ANY
  OF THE SERVICES WITH THE INTENTION OF OR FOR THE PURPOSE OF
  COMPLYING WITH ANY LAW, RULE, REGULATION, INDUSTRY STANDARD, OR
  POLICY, LICENSEE ACKNOWLEDGES AND AGREES THAT SUCH SERVICES ARE,
  IN THAT REGARD, PROVIDED “AS IS,” AND LICENSEE ASSUMES FULL
  RESPONSIBILITY FOR ITS COMPLIANCE. LICENSEE AGREES THAT REVENUE
  INSTITUTE SHALL HAVE NO LIABILITY TO LICENSEE FOR LICENSEE’S USE OF
  OR RELIANCE ON ANY SERVICES FOR SUCH PURPOSES. THIS PARAGRAPH IS
  NOT INTENDED TO DIMINISH, MODIFY, OR RELEASE ANY EXPRESS
  REPRESENTATIONS AND WARRANTIES STATED HEREIN.</p>
  <h4>12. AUDIT</h4>
  <p>During the Term of this Agreement and for a period of two (2) years after its expiration
  or termination, Licensee shall maintain complete and accurate records of Licensee’s
  use of the Licensed Materials and Revenue Institute Technology sufficient to verify
  compliance with this Agreement. Licensee shall permit Revenue Institute and its
  auditors, upon reasonable advance notice and during normal business hours, to
  examine such records and any systems used by Licensee in connection with the
  Licensed Materials. The scope of any such audit will be limited to verification of
  Licensee’s compliance with the terms of this Agreement. Any audit performed under this
  paragraph shall be at Revenue Institute’s expense, unless the audit uncovers material
  non-compliance with this Agreement, in which case, Licensee shall reimburse Revenue
  Institute for its reasonable out-of-pocket expenses incurred in performing such audit.</p>
  <h4>13. MISCELLANEOUS PROVISIONS</h4>
  <p>13.1 Marketing. Licensee hereby authorizes Revenue Institute to use Licensee’s name
  and logo for its marketing efforts unless and until such authorization is revoked in
  writing.</p>
  <p>13.2 Assignment. Either party hereto may assign this Agreement to a successor-in-
  interest pursuant to an acquisition of such party (whether by merger, stock sale, or
  asset sale) without the other party’s consent, provided however that (a) Licensee’s
  assignment hereof shall be effective only after fourteen (14) days’ written notice to
  Revenue Institute, and (b) Licensee may not assign this agreement to any competitor of
  Revenue Institute without Revenue Institute’s express written consent. No rights or
  obligations under this Agreement may be assigned or delegated except as provided in
  this section without the prior written consent of the other party, and any assignment or
  delegation in violation of this section shall be void.</p>
  <p>13.3 Notices. Licensee shall provide an email address for notices under this Agreement.
  All notices or other communications permitted or required to be given hereunder shall
  be sent by electronic mail to the email address provided by the other party for such
  purpose and shall be deemed given when sent. Notices to Revenue Institute shall be
  sent to <a href="mailto:legal@revenueinstitute.com" className="link">legal@revenueinstitute.com</a>. If Licensee fails to provide an email address for
  notices, Revenue Institute may provide notices hereunder by any means reasonably
  calculated to provide Licensee with actual notice thereof.</p>
  <p>13.4 Currency. All monetary amounts specified in this Agreement are in United States
  dollars unless otherwise expressly stated.</p>
  <p>13.5 Suggestions and Feedback. Revenue Institute shall have a royalty-free, worldwide,
  transferable, sub-licensable, irrevocable, perpetual license to use or incorporate into the
  Services any suggestions, enhancement requests, recommendations or other feedback
  provided by Licensee, including Authorized Users, relating to the operation of the
  Services provided such information does not include any Licensee Confidential
  Information.</p>
  <p>13.6 Entire Agreement. This Agreement constitutes the entire Agreement of the parties
  and supersedes all prior communications, understandings, and agreements relating to
  the subject matter hereof, whether oral or written. Any un-expired subscription set forth
  in any Ordering Document or agreement between the parties for access to Revenue
  Institute Services shall be governed by the terms hereof.</p>
  <p>13.7 Amendment. Revenue Institute may propose amendments to this Agreement at
  any time by providing notice of such proposed amendments in a manner permitted
  hereunder. Such proposed amendments shall be deemed accepted and become part of
  this Agreement thirty (30) days after the date such notice is given unless Licensee
  informs Revenue Institute that it does not accept such amendments. In the event
  Licensee informs Revenue Institute that it does not accept the proposed amendments,
  the proposed amendments will not take effect and the existing terms will continue in full
  force and effect. No other modification or claimed waiver of any provision of this
  Agreement shall be valid except by written amendment signed by authorized
  representatives of Revenue Institute and Licensee.</p>
  <p>13.8 Force Majeure. Neither Revenue Institute nor any of its affiliates will be liable for
  any delay or failure to perform any obligation under this Agreement where the delay or
  failure results from any cause beyond its reasonable control, including, but not limited
  to, acts of God, labor disputes or other industrial disturbances, electrical or power
  outages, utilities or other telecommunications failures, earthquake, storms or other
  elements of nature, blockages, embargoes, riots, acts or orders of government, acts of
  terrorism, or war.</p>
  <p>13.9 Export Compliance. The Services and derivatives thereof may be subject to export
  laws and regulations of the United States and other jurisdictions. Revenue Institute and
  Licensee each represents that it is not on any U.S. government denied-party list.
  Licensee will not permit any Authorized User to access or use any Services in a U.S.-
  embargoed country or region or in violation of any U.S. export law or regulation.</p>
  <p>13.10 Unenforceability and Survival. If any provision of this Agreement is held to be
  unenforceable, then that provision is to be construed either by modifying it to the
  minimum extent necessary to make it enforceable (if permitted by law) or disregarding it
  (if not permitted by law), and the rest of this Agreement is to remain in effect as written.
  Any obligations and duties which by their nature extend beyond the expiration or
  termination of this Agreement will survive the expiration or termination of this
  Agreement.</p>
  <p>13.11 Revenue Institute Contracting Entity, Governing Law, and Venue. The meaning of
  Revenue Institute, the law governing this Agreement, and the jurisdiction in which
  disputes shall be adjudicated will be based on Oakland Country jurisdiction in the State
  of Michigan in the U.S.</p>
  <p>1) The parties hereby agree to exclude all rights to seek a determination by the court of a
  preliminary point of law under section 45 of the Arbitration Act 1996 and all rights of
  appeal on a point of law from any arbitration award under section 69 of the Arbitration
  Act 1996. A person who is not party to this Agreement shall have no rights under the
  Contracts (Rights of Third Parties) Act 1999 or otherwise to enforce any term of this
  Agreement.</p>
  <p>2) Each party irrevocably consents to the personal jurisdiction of the state and federal
  courts located in the State of Washington for purposes of any lawsuit seeking to enforce
  this Agreement, and agrees that the exclusive venue for any litigation, action, suits, or
  proceeding arising out of or relating to this Agreement shall lie in the County Court in
  and for Clark County, Washington, or, if federal jurisdiction exists, in the United States
  District Court for the Western District of Washington.</p>
  <p>13.12 United States Government End-Users. The Services provided by Revenue
  Institute are “commercial items” consisting in part of “commercial computer software”
  and “computer software documentation,” as such terms are used in the Federal
  Acquisition Regulation (“FAR”) and the Defense Federal Acquisition Regulation
  Supplement (“DFARS”). In accordance with FAR 12.211 (Technical data) and FAR
  12.212 (Computer software), and DFARS 227.7102 (Commercial items, components, or
  processes) and DFARS 227.7202 (Commercial computer software and commercial
  computer software documentation), as applicable, the rights of the United States
  government to use, modify, reproduce, release, perform, display, or disclose computer
  software, computer software documentation, and technical data furnished in connection
  with the Services will be pursuant to the terms of this Agreement. This United States
  government rights clause is in lieu of, and supersedes, any other FAR, DFARS, or other
  clause or provision that addresses government rights in computer software, computer
  software documentation, or technical data. If a government agency needs additional
  rights, it must negotiate a mutually acceptable written addendum to this Agreement
  specifically granting those rights.</p>
        </div>
      </Container>
    </div>
  );
};

export default TermsOfService;
