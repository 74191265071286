import React, { useEffect, useState } from "react";
import InputField from "../InputField/InputField";

function InputDebounce({
  value: initialValue,
  variant,
  onChange,
  debounce = 1000,
  placeholder,
  sizeInput,
  width,
  icon = "search",
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number,
  placeholder?: string,
  width?: string
  variant?: "dark";
  sizeInput?: "tiny" | "small" | "large";
  icon?: string | null
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>)
{
  const [value, setValue] = useState(initialValue)
  useEffect(() => { setValue(initialValue) }, [initialValue])
  useEffect(() => {
    const timeout = setTimeout(() => {onChange(value)}, debounce)
    return () => clearTimeout(timeout)
  }, [value]) // eslint-disable-line
  return <InputField
    width={width}
    value={value}
    placeholder={placeholder}
    variant={variant}
    size={sizeInput}
    iconName={icon || undefined}
    onChange={(e: any) => setValue(e.target.value)}
  />
}

export default InputDebounce;
