import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { ClientDTO } from "utils/dtos/clientDTO";

const baseUrl = process.env.REACT_APP_BASE_URL;
interface ClientState {
  data: ClientDTO | null
  isLoading: boolean
  isSuccess: boolean
  errorMessage: string | unknown
}
const initialState: ClientState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: null,
};
export const getClientDTO = createAsyncThunk("client/get", async (clientId: string, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}client?client_id=${clientId}`, {withCredentials: true});
 
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const updateWebhookURL = createAsyncThunk("client/updateWebhookURL", async (updateWebhookDto: {clientId: string; clayWebhookUrl: string | null}, thunkAPI) => {
  try {
    const {clientId, clayWebhookUrl} = updateWebhookDto;
    const response  = await axios.patch(`${baseUrl}client/${clientId}`, {clayWebhookUrl}, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })

    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const clientSlice = createSlice({
  name: "crm", initialState, reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientDTO.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = null;
      state.data = action.payload.data;
    });
    builder.addCase(getClientDTO.rejected, (state, action: any) => {
      state.data = null;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = action.payload?.message || "Something went wrong";
    });
    builder.addCase(getClientDTO.pending, (state) => { state.isLoading = true; });

    builder.addCase(updateWebhookURL.fulfilled, (state, action) => {
      if (state.data) { 
        state.data.clayWebhookUrl = action.payload?.data.clayWebhookUrl
        state.isLoading = false
      };
    })

    builder.addCase(updateWebhookURL.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(updateWebhookURL.rejected, (state, action) => {
      state.isLoading = false;
    })
  },
});