import React, {
  ReactNode,
  useEffect,
  useState,
} from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useAppSelector } from "store/hooks";
import constants from "utils/constants";

const Wrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { data: user } = useAppSelector((state) => state.user);
  const { boot } = useIntercom();

  useEffect(() => {
    if (user?.email) {
      const intercomBootProps = {
        email: user.email,
        userId: user.userId,
        name: user.firstName,
        createdAt: "created_at_date",
      };

      boot(intercomBootProps);
    }
  }, [user]);

  return <>{children}</>;
};

export const IntercomContext: React.FC<{ children: ReactNode }> = ({children}) => {
  const bodyTheme = document.body.getAttribute("data-theme") as "identity_matrix" | "qualigence";
  const { data: user } = useAppSelector((state) => state.user);
  const [initialize, setInitialize] = useState(false);
  const [intercomKey, setIntercomKey] = useState<string | null>(
    bodyTheme === "qualigence"
      ? constants.QUALIGENCE_INTERCOM_KEY
      : constants.IDENTITY_MATRIX_INTERCOM_KEY,
  );

  useEffect(() => {
    if (user?.platformBrandId) {
      const appId =
        user.platformBrandId === 2
          ? constants.QUALIGENCE_INTERCOM_KEY
          : constants.IDENTITY_MATRIX_INTERCOM_KEY;

      setIntercomKey(appId);
    }
  }, [user]);
  useEffect(() => {
    if (intercomKey) {
      setInitialize(true);
    }
  }, [intercomKey]);

  return (
    <IntercomProvider
      autoBoot={false}
      apiBase="https://api-iam.intercom.io"
      shouldInitialize={initialize}
      appId={intercomKey as string}
    >
      <Wrapper>{children}</Wrapper>
    </IntercomProvider>
  );
};
