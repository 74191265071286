import CryptoJS from 'crypto-js'
import { EnrichmentOrigin } from 'utils/enums/enrichment';
interface IPage {
    category: string;
    name: string;
}
export default class SegmentAgent {
    public static event(properties: object, userId: string, eventName: string, platformBrandId?: number, method?: "track" | "identify") {
        /* used for HTTP request
        let API_KEY = this.handleAPIKey(platformBrandId)
        const headers = {
            'Authorization': `Basic ${API_KEY}`,
            'Content-Type': 'application/json',
        };

        let requestData = {
            userId,
            ...properties
        } as any
        requestData = method === 'identify' ? {...requestData, traits: properties} : {...requestData, ...properties}
        */
        
        if (!method) method = 'track'
        if (method ==='track')
            window.analytics.track(eventName, {
                userId, 
                ...properties
            })
        else {
            const hmac = CryptoJS.HmacSHA256(userId, String(process.env.REACT_APP_INTERCOM_APISECRET));
            const hash = hmac.toString(CryptoJS.enc.Hex);
            window.analytics.identify(userId, properties, {
                Intercom: {
                    user_hash: hash
                 }
            })
        }
    }
    public static async page(pageKey: string, properties: {pathName: string}, userId?: string, platformBrandId?: number) {
        
        const allPageData = this.getPageData()
        const pageData = allPageData[pageKey as keyof object] as IPage
        if (!pageData) return
        
        window.analytics.page(pageData?.category || 'UNDEFINED', pageData?.name || 'UNDEFINED', {
            ...properties,
            title: pageData.name
        })

        /* HTTP request
        let API_KEY = this.handleAPIKey(platformBrandId)
        const headers = {
            'Authorization': `Basic ${API_KEY}`,
            'Content-Type': 'application/json',
        };
        const requestData = {
            userId,
            name: pageName
        }
        await axios.post('https://api.segment.io/v1/page', requestData, { headers })
        */
    }
    public static getEventEnrichmentName(requestType: EnrichmentOrigin) {
        switch (requestType) {
            case EnrichmentOrigin.SINGLE_ENRICHMENT_EMAIL: default: return 'SINGLE_SEARCH_EMAIL';
            case EnrichmentOrigin.SINGLE_ENRICHMENT_LINKEDIN: return 'SINGLE_SEARCH_LINKEDIN';
            case EnrichmentOrigin.SINGLE_ENRICHMENT_PHONE: return 'SINGLE_SEARCH_PHONE';
            case EnrichmentOrigin.SINGLE_ENRICHMENT_NAME_COMPANY: return 'SINGLE_SEARCH_NAMECOMPANY';
            case EnrichmentOrigin.SINGLE_ENRICHMENT_ADDRESS: return 'SINGLE_SEARCH_LOCATION';
        }
    }
    private static getPageData() {
        return {
            home: {name: 'Home', category: 'home'},
            searchHistory: {name: 'Search History', category: 'lead'},
            accountSettings: {name: 'Account Settings', category: 'account-settings'},
            authSignUp: {name: 'Sign Up', category: 'auth-signup'},
            authSignIn: {name: 'Login', category: 'auth-login'},
            authEmailVerify: {name: 'Email Verification', category: 'auth-email-verify'},
            authPasswordForgot: {name: 'Forgot Password', category: 'auth-password-forgot'},
            cartBuyCredits: {name: 'Purchase Credits', category: 'cart-buy-credits'},
            billingPayment: {name: 'Billing & Payment', category: 'billing-payment'},
            paymentSuccess: {name: 'Payment Successful', category: 'payment-successful'},
        }
    }
}
