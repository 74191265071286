import React, {useCallback, useMemo, useState} from 'react';
import constants from "../constants";
import {toast} from "react-toastify";

type Props ={
    leads: any[]
    count: number
}

export const useTableSelect = ({leads, count}: Props) => {
    const [unselectedRows, setUnselectedRows] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [isModalExportOpen, setIsModalExportOpen] = useState(false);
    const [isAllListSelected, setIsAllListSelected] = useState(false);
    const [anchorExportSelectMenu, setAnchorExportSelectMenu] = useState<null | HTMLElement>(null);

    const isExportSelectMenuOpen = Boolean(anchorExportSelectMenu);

    const resetExport = useCallback(() => {
        setUnselectedRows([]);
        setSelectedRows([]);
        setIsAllListSelected(false);
    },[]);

    const leadIds = useMemo(() => leads.map(lead => lead.leadId), [leads]);

    const isAllRowsInPageSelected = useMemo(() => {
        return selectedRows.filter((leadId) => leadIds.includes(leadId)).length === leads.length
    }, [selectedRows, leadIds]);

    const handleSelectPeoplePage = useCallback(() => {
        if (isAllRowsInPageSelected) {
            setIsAllListSelected(false);
            setSelectedRows([]);
        } else setSelectedRows(leads.map((lead) => lead.leadId)); // Assuming each lead has a unique 'id' property
    },[isAllRowsInPageSelected, leads]);

    const handleSelectAllPeople = useCallback(() => {
        setIsAllListSelected(true);
        if (count < constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK) return;
        toast.info(
            `To ensure optimal performance, the maximum number of selections allowed is ${constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK.toLocaleString()}.`,
            { autoClose: 4000, toastId: "selectAllPeople" }
        );
    },[count])
    const isSelectAllChecked = useMemo(() => count !== 0 && (isAllListSelected || isAllRowsInPageSelected),[count, isAllListSelected,isAllRowsInPageSelected])


    return {
        unselectedRows, setUnselectedRows, selectedRows, setSelectedRows,
        isModalExportOpen, setIsModalExportOpen, isAllListSelected, setIsAllListSelected, setAnchorExportSelectMenu,
        isExportSelectMenuOpen, anchorExportSelectMenu, resetExport, isAllRowsInPageSelected, handleSelectPeoplePage, handleSelectAllPeople,
        isSelectAllChecked
    }
};

