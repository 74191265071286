import Header from "components/molecules/Header/Header";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { logout, resetAuthSlice } from "store/slices/auth.slice";
import { resetCardSlice } from "store/slices/card.slice";
import { resetListSlice } from "store/slices/list.slice";
import { resetTransactionsSlice, resetUpdateUser } from "store/slices/transactions.slice";
import TopMessage from "components/atoms/TopMessage/TopMessage";
import { resetSendAgain, resendVerificationEmail } from "store/slices/email.slice";
import { toast } from "react-toastify";
import { getPersonalInfo } from "store/slices/user.slice";
import EntryFormatter from "utils/EntryFormatter";
import { getClientDTO } from "store/slices/client.slice";

const Protected: React.FC<any> = ({ children }: any) => {
  const { data: user, isSuccess: isSuccessUser } = useAppSelector((state) => state.user);
  const { data: client, isLoading: clientIsLoading, isSuccess: clientIsSuccess } = useAppSelector((state) => state.client);
  const { isSuccess, errorMessage, successMessage, isLoading } = useAppSelector((state) => state.email.sendAgain);
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("accessToken");
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    dispatch(logout());
    dispatch(resetAuthSlice());
    dispatch(resetCardSlice());
    dispatch(resetListSlice());
    dispatch(resetTransactionsSlice());
    dispatch(resetUpdateUser());
  }
  let decodedToken: any;
  try {
    decodedToken = jwt_decode(token || "");
    if (Date.now() > decodedToken.exp * 1000) {
      decodedToken = false;
      handleLogout()
    }
  } catch (e) {
    localStorage.removeItem("accessToken");
    decodedToken = false
  }
  const childrenWithProps = React.Children.map(children, (child) => {
    if (typeof child.type !== "string") return React.cloneElement(child, { decodedToken });
    return child;
  });
  const handleSendAgain = () => { if (token && !user.sentEmail) dispatch(resendVerificationEmail(token)); };
  useEffect(() => {
    if (!isSuccessUser) return;
    if (EntryFormatter.isObjectEmpty(user)) handleLogout()
  }, [user, isSuccessUser]);
  useEffect(() => {
    if (!errorMessage || !successMessage) return;
    if (errorMessage) toast.error(String(errorMessage));
    else if (successMessage) toast.success(successMessage);
    dispatch(getPersonalInfo());
    dispatch(resetSendAgain());
  }, [isSuccess]);
  useEffect(() => {
    if (EntryFormatter.isObjectEmpty(user)) dispatch(getPersonalInfo());
  }, []);
  useEffect(() => {
    if (!EntryFormatter.isObjectEmpty(user) && !clientIsLoading && !clientIsSuccess && !client) {
      dispatch(getClientDTO(user.clientId));
    }
  }, [user, clientIsSuccess]);
  return decodedToken ? (
    <div className="flex flex-col gap-7 sm:gap-10 pt-5 sm:pt-6 pb-6 sm:pb-12">
      <Header loggedIn token={token || ""} />
      {user.email && !user.hasVerifiedEmail && (
        <TopMessage type="warning"><p>
          Please verify your email. Please check your email.{" "}
          {isLoading
            ? <span style={{ textDecoration: "underline #4253ff", color: "#4253ff" }}>Please wait...</span>
            : !user.sentEmail
              ? <span style={{ textDecoration: "underline #4253ff", color: "#4253ff", cursor: "pointer" }} onClick={handleSendAgain}>Send again</span>
              : <></>}
        </p></TopMessage>
      )}
      {childrenWithProps}
    </div>
  ) : (
    <Navigate to={"/welcome"} />
  );
};

export default Protected;
