import React from "react";
import Icon from "components/atoms/Icon/Icon";
import { InputDropDown } from "components";
const LeadHistoryFooter: React.FC<{
  isLoading: boolean;
  isSuccess: boolean;
  numberOfLeads: number;
  size: number;
  page: number;
  pagination: number[];
  paginationLast: number;
  isLastPage: boolean;
  tableColumns: number;
  isMobile: boolean;
  handleSetSize: Function;
  loadPage: Function;
}> = ({
  isLoading,
  isSuccess,
  numberOfLeads,
  size,
  page,
  pagination,
  paginationLast,
  isLastPage,
  tableColumns,
  isMobile,
  handleSetSize,
  loadPage,
}) => {
  return (
    <tfoot style={{ backgroundColor: "var(--color-gray-900)", borderTop: `1px solid var(--color-paper-600)` }}>
      <tr>
        <td className="hidden sm:table-cell" colSpan={2}>
          {!isLoading && isSuccess && numberOfLeads > 0 && (
            <>
              {"Showing "}
              <span className="highlight">
                {size * page - (size - 1)}-
                {isLastPage ? numberOfLeads : size * page}
              </span>
              {" of "}
              <span className="highlight">{numberOfLeads}</span>
            </>
          )}
        </td>
        <td className="text-right" colSpan={isMobile ? 2 : tableColumns - 2}>
          {!isLoading ? (
            <>
              {isSuccess && numberOfLeads > 0 ? (
                <div className="flex sm:flex-row gap-4 sm:gap-6 justify-end">
                  <div className="flex items-center gap-3">
                    <p className="text-gray-300">Results per page:</p>
                    <InputDropDown
                      triggerTitle={size}
                      menuArray={[
                        {
                          title: "20",
                          onClick: () => handleSetSize(20),
                          isSelected: size === 20,
                        },
                        {
                          title: "100",
                          onClick: () => handleSetSize(100),
                          isSelected: size === 100,
                        },
                        {
                          title: "500",
                          onClick: () => handleSetSize(500),
                          isSelected: size === 500,
                        },
                      ]}
                    />
                  </div>
                  <div className="pagination">
                    <div
                      className={`${page === 1 ? `disabled` : ``}`}
                      onClick={() => loadPage(page - 1)}
                    >
                      <Icon name="chevron-left" size={13} />
                    </div>
                    {pagination.map((i) => {
                      const isPageCurrent = page === i;
                      if (i === 0 && i !== paginationLast) return null;
                      return (
                        <div
                          className={`${isPageCurrent ? `active` : ``}`}
                          key={"pagination-page-" + i}
                          onClick={() => loadPage(i)}
                        >
                          {i}
                        </div>
                      );
                    })}
                    {!pagination.includes(paginationLast) && (
                      <>
                        {!pagination.includes(paginationLast - 1) && (
                          <div className="info">...</div>
                        )}
                        <div
                          className={`${isLastPage ? `active` : ``}`}
                          onClick={() => loadPage(paginationLast)}
                        >
                          {paginationLast}
                        </div>
                      </>
                    )}
                    <div
                      className={`${isLastPage ? `disabled` : ``}`}
                      onClick={() => loadPage(page + 1)}
                    >
                      <Icon name="chevron-right" size={13} />
                    </div>
                  </div>
                </div>
              ) : (
                <p>&nbsp;</p>
              )}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </td>
      </tr>
    </tfoot>
  );
};
export default LeadHistoryFooter;
