import Button from "components/atoms/Button/Button";
import React, { useEffect } from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "components";
import constants from "utils/constants";
import { useChameleonIdentify } from "utils/hooks/useChameleonIdentify";

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    window.analytics.page("Payment Successful")
  },[]) // eslint-disable-line
  useChameleonIdentify();
  
  return (
    <div className={classNames(styles.container)}>
      {Array.from({ length: 3 }, (_, i) => i).map((elm) => {
        return (
          <div
            className={styles.circle}
            style={{
              width: `${144 + (elm + 1) * 64}px`,
              height: `${144 + (elm + 1) * 64}px`,
              opacity: elm === 1 ? 1 : 0.5,
            }}
            key={elm}
          >
            {elm === 1 && <div className={styles.circle_filled}><Icon name="credits" /></div>}
          </div>
        )
      })}
      <div className={styles.box}>
        <h2 className="text-3xl">Payment Complete</h2>
        <div className="text-center text-white-500">
          <h1 className="text-2xl mt-10 mb-2">+{(searchParams.get("amount") !== 'undefined' && searchParams.get("amount"))|| ""} Credits</h1>
          <p className="mt-5 text-base">The credits will be added to your account within minutes.</p>
          <p className="mt-5 text-base">
            If you don't receive your credits withint 10 minutes, please contact our team by the email <a className="link" href={`mailto:${constants.COMPANY_1_EMAIL_SUPPORT}`}>{constants.COMPANY_1_EMAIL_SUPPORT}</a>. We are here to help you enjoy the full range of services we offer.
          </p>
        </div>
      </div>
      <Button onClick={() => navigate("/")}>Go to Dashboard</Button>
    </div>
  );
};

export default SuccessPage;
