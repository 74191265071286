import React, {Dispatch, SetStateAction, useEffect, useMemo} from "react";
import { RowData, flexRender, Row } from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { InterfacePixelLeadData, getLeadStats, setLeadStats } from "store/slices/insights";
import { secondsToHMS } from "utils/workWithData";
import LeadScore from "components/atoms/LeadScore/LeadScore";
import Skeleton from "components/atoms/layout/Skeleton/Skeleton";
import { useNavigate } from "react-router-dom";
import constants from "utils/constants";
import { getPersonalInfo } from "store/slices/user.slice";
import LeadHelper from "helpers/LeadHelper";
import { toast } from "react-toastify";
import { messages } from "utils/message";
import Loading from "components/atoms/Loading/Loading";
import {Checkbox} from "@mui/material";
declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "range" | "select" | "none";
    size?: string;
}}
const PulseRow: React.FC<{ row: Row<InterfacePixelLeadData>, isSelected: boolean; setSelectedRows: Dispatch<SetStateAction<string[]>>; }> = ({ row, setSelectedRows, isSelected = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading: CRIsLoading, isSuccess: CRIsSuccess, data: CRData } = useAppSelector((state) => state.insights.contactsRevealed.find((x) => x.id === row.original.id)) ?? {};
  const { leadsStats } = useAppSelector((state) => state.insights);
  const getRowStats = useMemo(() => async () => {
    if (stats) return;
    dispatch(getLeadStats({ leadId: row.original.id }));
  }, [row.original.id]);
  const stats = leadsStats.find((i) => i.leadId === row.original.id);

  const handleCheckboxChange = (rowId: string) => {
    setSelectedRows((prev) => {
      if (prev.includes(rowId)) return prev.filter((id) => id !== rowId);
      else return [...prev, rowId];
    })
  };
  useEffect(() => { getRowStats() }, []);
  useEffect(() => {
    if (stats) 
      dispatch(setLeadStats({id: row.original.id, duration: secondsToHMS(stats.duration), visits: stats.visits, pageViews: stats.pageViews, score: stats.leadScore} as any))
  }, [stats])

  useEffect(() => {
    if (!CRIsSuccess || !CRData) return;
    setTimeout(() => {dispatch(getPersonalInfo())}, 10000)
  }, [CRIsSuccess]); // eslint-disable-line
  return <tr>
    {row.getVisibleCells().map((cell) => {
      const value = cell.getValue() as any;

      return <td key={cell.id} className={`p-4`}
        onClick={() => {navigate(`/lead/${row.original.id}?app_source=${constants.PIXEL_KEYWORD}`)}}
        style={{
          textAlign: cell.column.id === "orgName" ? "left" : "center",
          width: cell.column.columnDef.meta?.size ?? "auto",
        }}
        title={ typeof value === "string" && value.length > 12 ? value : ""}
      >
        {cell.column.id === "isContactRevealed"
          ? <>{CRIsLoading
            ? <Loading height="auto" spinnerSize={32} />
            : flexRender(cell.column.columnDef.cell, cell.getContext())
          }</>
          : flexRender(cell.column.columnDef.cell, cell.getContext())
        }
        {cell.column.id === "leadScore"
            && <div className="flex items-center">
              <Checkbox
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckboxChange(cell.row.original?.id);
                  }}
                  checked={isSelected}
                  sx={{
                    color: "var(--color-gray-600)",
                    '&.Mui-checked': { color: "var(--color-primary-500)" },
                    "&:hover": { backgroundColor: "transparent" },
                  }}
              />
              {stats?.leadScore !== undefined
                  ? <div className="flex justify-center"><LeadScore value={stats.leadScore} /></div>
                  : <Skeleton width={40} height={20} />
              }
            </div>
        }
        {cell.column.id === "visits" &&
          <>{stats?.visits !== undefined  && stats?.visits !== null ? '' : <Skeleton width={40} height={20} />}</>
        }
        {cell.column.id === "pageViews" &&
          <>{stats?.pageViews !== undefined && stats?.pageViews !== null  ? '' : <Skeleton width={40} height={20} />}</>
        }
        {cell.column.id === "duration" &&
          <>{stats?.duration !== null && stats?.duration !== undefined ? '' : <Skeleton width={40} height={20} />}</>
        }
      </td>
    })}
  </tr>
};
export default PulseRow;
