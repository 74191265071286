
export const hmsToSeconds = function hmsToSecondsOnly(str: string | null) {

    if (!str) return 0;

    const p = str.split(':');
    let s = 0, m = 1;

    while (p.length > 0) {
        s += m * parseInt(p.pop()!, 10);
        m *= 60;
    }

    return s;
}