import React from 'react';

import { MdCardMembership, MdCheck, MdLogout, MdOutlineHandshake,
} from 'react-icons/md';
import { RiOrganizationChart, RiUserLocationLine,
} from 'react-icons/ri';
import { HiMenuAlt1, HiOutlineCreditCard, HiPlus,  } from 'react-icons/hi';
import { FiSearch, FiDownloadCloud, FiLock, FiThumbsDown, FiDollarSign, FiMonitor, FiFilter } from 'react-icons/fi';
import { BiBriefcase, BiCategory, BiCodeBlock, BiMaleFemale, BiPencil, BiSave, } from 'react-icons/bi';
import { IoMdClose, IoMdTime } from "react-icons/io";
import {
    FaUserCircle, FaChevronLeft, FaChevronRight, FaChevronDown, FaChevronUp, FaUserPlus, FaBinoculars, FaRegSadTear, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaCheckCircle, FaLaptopCode,
    FaRegStar, FaRegMeh, FaCaretDown, FaCaretUp, FaSearchDollar, FaRegClock,
} from 'react-icons/fa';
import {
    BsGlobeAmericas,
    BsLinkedin, BsInfoCircle, BsFileEarmarkText, BsTrash3, BsFolder, BsEnvelope, BsTelephone, BsPersonFillCheck, BsPersonFill, BsSortDown, BsSortDownAlt, BsBuildings,
} from 'react-icons/bs';

import {
    LuCoins,
    LuSearchCheck,
    LuSearchX,
    LuLoader2,
    LuRefreshCw,
    LuBadgePercent,
    LuMousePointerClick,
    LuCopy,
    LuLayers,
    LuMonitorX,
} from 'react-icons/lu';
import { IoPaperPlaneOutline, IoPeople, IoCalendarOutline, IoSpeedometerOutline, IoLocationOutline, IoEyeOutline, IoMedalOutline, } from 'react-icons/io5';
import { TbBrandZapier } from 'react-icons/tb';

interface IIcon {
	name: string;
    size?: number;
    color?: string;
    className?: string
}
const Icon: React.FC<IIcon> = ({
	name,
    size = 20,
    color,
    className
}: IIcon): JSX.Element => {
    const params = { size, color, className }
    switch (name) {
        case 'caret-up': return <FaCaretUp {...params} />
        case 'caret-down': return <FaCaretDown {...params} />

        case 'angle-left-double': return <FaAngleDoubleLeft {...params} />
        case 'angle-left': return <FaAngleLeft {...params} />
        case 'angle-right': return <FaAngleRight {...params} />
        case 'angle-right-double': return <FaAngleDoubleRight {...params} />

        case 'chevron-left': return <FaChevronLeft {...params} />
        case 'chevron-right': return <FaChevronRight {...params} />
        case 'chevron-down': return <FaChevronDown {...params} />
        case 'chevron-up': return <FaChevronUp {...params} />

        case 'sort-down': return <BsSortDown {...params} />
        case 'sort-up': return <BsSortDownAlt {...params} />

        case 'company': return <BsBuildings {...params} />
        case 'job': return <BiBriefcase {...params} />
        case 'industry': return <RiOrganizationChart {...params} />
        case 'seniority': return <IoMedalOutline {...params} />
        case 'category': return <BiCategory {...params} />

        case 'phone': return <BsTelephone {...params} />
        case 'email': return <BsEnvelope {...params} />
        case 'paperplane': return <IoPaperPlaneOutline {...params} />

        case 'search': return <FiSearch {...params} />
        case 'search-check': return <LuSearchCheck {...params} />
        case 'search-x': return <LuSearchX {...params} />

        case 'download': return <FiDownloadCloud {...params} />
        case 'save': return <BiSave {...params} />
        case 'copy': return <LuCopy {...params} />

        case 'people': return <IoPeople {...params} />
        case 'membership': return <MdCardMembership {...params} />
        case 'user-location': return <RiUserLocationLine {...params} />
        case 'user': return <BsPersonFill {...params} />
        case 'user-circle': return <FaUserCircle {...params} />
        case 'user-check': return <BsPersonFillCheck {...params} />
        case 'user-plus': return <FaUserPlus {...params} />

        case 'pulse': return <FaLaptopCode {...params} />
        case 'layers': return <LuLayers {...params} />
        case 'lock': return <FiLock {...params} />
        case 'logout': return <MdLogout {...params} />
        case 'card': return <HiOutlineCreditCard {...params} />
        case 'trash': return <BsTrash3 {...params} />
        case 'globe': return <BsGlobeAmericas {...params} />
        case 'code': return <BiCodeBlock {...params} />
        case 'page': return <FiMonitor {...params} />
        case 'pageNotFound': return <LuMonitorX {...params} />
        case 'mouse': return <LuMousePointerClick {...params} />
        case 'sad': return <FaRegSadTear {...params} />
        case 'gender': return <BiMaleFemale {...params} />

        case 'location': return <IoLocationOutline {...params} />

        case 'menu': return <HiMenuAlt1 {...params} />
        case 'refresh': return <LuRefreshCw {...params} />
        case 'time': return <IoMdTime {...params} />
        case 'clock': return <FaRegClock {...params} />
        case 'eye': return <IoEyeOutline {...params} />

        case 'badge-percent': return <LuBadgePercent {...params} />
        case 'folder': return <BsFolder {...params} />
        case 'document': return <BsFileEarmarkText {...params} />
        case 'info': return <BsInfoCircle {...params} />
        case 'plus': return <HiPlus {...params} />
        case 'close': return <IoMdClose {...params} />
        case 'check': return <MdCheck {...params} />
        case 'check-circle': return <FaCheckCircle {...params} />
        case 'loader': return <LuLoader2 {...params} />
        case 'filter': return <FiFilter {...params} />

        case 'credits': return <LuCoins {...params} />
        case 'revenue': return <FiDollarSign {...params} />
        case 'income': return <FaSearchDollar {...params} />

        case 'handshake': return <MdOutlineHandshake {...params} />
        case 'score': return <IoSpeedometerOutline {...params} />
        case 'star': return <FaRegStar {...params} />
        case 'face-meh': return <FaRegMeh {...params} />
        case 'thumbs-down': return <FiThumbsDown {...params} />

        case 'linkedin': return <BsLinkedin {...params} />
        case 'zapier': return <TbBrandZapier {...params} />

        case 'calendar-alt': return <IoCalendarOutline {...params} />

        case 'edit': return <BiPencil {...params} />
        case 'view': return <FaBinoculars {...params} />

        default: return <div>no icon</div>
    }
};

export default Icon;
