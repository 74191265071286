import { ISvg } from "../../types/components/svgIcons.type";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Lock } from "../../assets/iamges/lock.svg";
import { ReactComponent as Received } from "./received.svg";
import { ReactComponent as Decline } from "./declined.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as ArrowDown } from "./arrowDown.svg";
import { ReactComponent as ArrowUp } from "./arrowUp.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Rewind } from "./rewind.svg";
import { ReactComponent as Folder } from "./folder.svg";
import { ReactComponent as ArrowBack } from "./arrowBack.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Document } from "./document.svg";
import { ReactComponent as Spinner } from "./spinner.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Credits } from "./credits.svg";
import { ReactComponent as VisaCard } from "./visaCard.svg";
import { ReactComponent as MasterCard } from "./masterCard.svg";
import { ReactComponent as Amex } from "./amex.svg";
import { ReactComponent as Discover } from "./discover.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as BurgerIcon } from "./burgerIcon.svg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Email } from "./email.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as PlusRound } from "./plusRound.svg";
import { ReactComponent as MinusRound } from "./minusRound.svg";

const Svg: ISvg = {
  close: Close,
  lock: Lock,
  received: Received,
  decline: Decline,
  delete: Delete,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  copy: Copy,
  search: Search,
  rewind: Rewind,
  folder: Folder,
  arrowBack: ArrowBack,
  pencil: Pencil,
  document: Document,
  spinner: Spinner,
  download: Download,
  credits: Credits,
  visa: VisaCard,
  mastercard: MasterCard,
  amex: Amex,
  discover: Discover,
  plus: Plus,
  burgerIcon: BurgerIcon,
  logo: Logo,
  email: Email,
  info: Info,
  plusRound: PlusRound,
  minusRound: MinusRound
};

export default Svg;
